.screen-size-change-warning {
    display: flex;
    position: absolute;
    width: 100%;
    height: 5%;
 
    align-items: center;
    justify-content: center;

    bottom:0 ;


}

.screen-size-change-warning span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2% 0 2%;
    box-sizing: border-box;
    background-color: rgb(0, 240, 0);
    color: black;
    height: 100%;
    border-radius: 1em;
}
