@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');




:root{
   --main-color: rgba(32, 33, 36, 1);
   --accent-color : rgb(53, 53, 53);
   --blue-accent : rgb(61, 80, 250);
   --gray-colr : rgb(80, 79, 79);
}



body {
  font-family: 'Poppins', sans-serif;
  color: white;
  background-color: var(--main-color);
  
}

a{
  text-decoration: none;
  color: white;
}

.header{
  position: relative;
  margin-top: 4vh;
  margin-bottom: 10vh;

}

.header-menu-icon-container{
  position:absolute;
  top:0;
  left:50px;

}

.header-menu-icon{

  cursor: pointer;
  font-size: 40px;
}

.header-profile{
  display: flex;
  position: relative;
  background-color: var(--accent-color);
  width:fit-content;
  border-radius: 15px;
}

.header-profile-card{
  position:absolute;
  top: 0;
  left: 80%;
}

#main-heading{
  
  text-align: center;
}


.user-name-heading{
  text-align: center;
}

.header-menu-container{
  background-color: white;
  color: black;
  border-radius: 15px;
}

.header-menu-item{
  border-bottom: 2px solid black;
  width: 150px;
  margin-bottom: 5px;
}



.properties-menu{
  position: absolute;
  top: 5vh;
  left: 1vw;

  width: 18vw;
  height: 50vh;
  background-color: var(--accent-color);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.proprty-tabs-pane{
  height: 10%;
  display: flex;
  border-radius: 8px;
}

.property-tab-button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1%;
  font-size:  90%;
  
  border-right:  2px solid black;

  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;

}


.project-panel{
  position: absolute;
  text-align: center;
  top: 65vh;
  left: 1vw;
  width: 18vw;
  height: max-content;
  background-color: var(--accent-color);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.spawner-select{
  border: 1px solid white;
  margin: 5%;
  display:flex;
  flex-direction: column;
  padding: 10% 0 10% 0 ;
  box-sizing: border-box;
  align-items: center;

}

.spawner-properties{
  position: relative;
}

.spawner-properties-menu{
  display: grid;
  align-items: center;
  height: 20vh;
  width: 5vw;
  padding: 3px;
  background-color: white;
  color: black;
  border-radius: 0.3vw;
  position: absolute;
  top : 3vh;
  left:9.5vw;
}


.spawner {
  cursor: pointer;

  height: max-content;
  border-radius: 1vw;
   width: 80%;
   background-color: var(--main-color);
}
.physics-engine{
  position: absolute;
  left:20vw;
  top: 15vh;
  /* width: 700px;
  height: 800px; */
}




/* .rectangle{

} */

.circle{

  border-radius: 100px;

}



.reset-button{
  width: max-content;
  background-color: var(--accent-color);
  border-radius: 5px;
  cursor: pointer;
}

.reset-button :hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  
}
.footer{
  height: max-content;
  background-color: var(--accent-color);
  position: relative; 
  top:80vh;
  text-align: center;

  /* background-color: rgb(53, 51, 51); */

}

.made-with-text{
  /* background-color: var(--accent-color); */
  /* border-radius: 15px; */
  /* padding-left: 10px;
  padding-right: 10px; */
  position: relative;
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 10px; */
}

.graphs-menu{

  position: absolute;
  top: 18vh;
  left: 77.8vw;

  width: 21vw;
  height: 55vh;
  background-color: var(--accent-color);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.graphs-tabs-pane{

  height: 10%;
  display: flex;
  border-radius: 8px;

}

/* .charts-tab{

  width: auto;
  height: 90%;
  background-color: red;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;

} */

.charts-tab{
  background-color: var(--gray-colr);
  margin: 10px;
}

.bar-graph{

  /* height: 10%; */
  height: 42vh;
  /* background-color: red; */

  /* position: absolute; */
}

.test-this{
  background-color: green;
}

.graph-properties{

  background-color: var(--gray-colr);
  position: relative;
  padding: 10px;
  padding-bottom: 0;
  top:0;
  height: 41.5vh;

}

.select-body-graph-button{
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
}