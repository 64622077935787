
.world-properties{
    background-color:var(--gray-colr);
    position: absolute;
    padding: 4.5%;
    box-sizing: border-box;
    top:10%;
    height: 100%;
   width: 100%;
   font-size: 115%

  }